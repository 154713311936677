import ContactForm from "../components/ContactForm";
import Navigation from "../components/Navigation";
import { FaHome, FaPhone, FaEnvelope, FaFacebookF } from "react-icons/fa";
import Footer from "../components/Footer";

const Contact = () => {
    return (
        <div>
            <Navigation />
            <ContactForm />
            <div className="links">
                <img src={require("../assets/images/contact.jpg")} className='contactBack' alt='' draggable={false} />
                <span>
                    <FaHome />
                    <h2>Adresse</h2>
                    <p>Daumazan sur Arize</p>
                </span>
                <span>
                    <FaPhone />
                    <h2>Téléphone</h2>
                    <p>07 70 10 04 28</p>
                </span>
                <span>
                    <FaEnvelope />
                    <h2>Email</h2>
                    <p><a href="mailto:assoespritdemeute@gmail.com" target="_blank" rel="noreferrer">assoespritdemeute@gmail.com</a></p>
                </span>
                <span>
                    <FaFacebookF className="facebook" />
                    <h2>Facebook</h2>
                    <p>Suivez notre <a href="https://www.facebook.com/espritdemeute">page</a></p>
                </span>
            </div>

            <Footer />
        </div>
    );
}

export default Contact;