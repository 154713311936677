import { NavLink } from 'react-router-dom';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';

const Home = () => {
    return (
        <div>
            <Navigation />
            
            <img src={require("../assets/images/dog.jpg")} className='imgBack' alt='' draggable={false} />
            <div className='mainPage'>
                <h1 className="siteTitle">Bienvenue sur notre Site professionnel</h1>
                <p className='siteDesc'>Esprit de Meute vous propose de l’Éducation Canine et des activités en contact avec le chien nordique plus communément appelé « chien de traîneau ».</p>
                <p className="siteDesc">Mon désir, vous faire partager ma passion, mon travail, l’amour des chiens mais aussi de mes propres chiens autour de multiples activités.</p>
                
                <h2 className="info">QUI SUIS-JE</h2>
                <p className="siteDesc">Je m’appelle Carole BRUNEAU, je suis éducateur canin diplômée d’état et comportementaliste depuis 5 ans. Je me suis spécialisée dans les races nordiques mais je travaille avec toutes les races sauf les chiens catégorisés</p>
                <p className="siteDesc">Je suis également musher (conducteur de chien de traîneau) avec l'assossiation Esprit De Meute. Je vous propose de l'initiation aux Cani balades et la découverte du kart à chiens (possibilité de baptêmes en traineau quand je travaille chez des mushers pro).</p>
                <p className="siteDesc">J’ai 8 ans d’expérience dans les sports canins, canimarche, canicross, canivtt, canitrottinette et attelages (kart à chiens et traîneau).</p>
                <p className="siteDesc">Ma passion pour le chien a démarré très tôt, dès ma naissance car j’ai grandi au milieu de chiens de chasse mais les lectures de Jack London et l’univers du chien de traîneau m’ont toujours fasciné.</p>
                
                <h2 className="info">MES DIPLOMES</h2>
                <p className="siteDiplomes">Je suis titulaire du :</p>
                <p>- BP Éducateur Canin et comportementaliste</p>
                <p>- CAP Petite enfance</p>
                <p>- Secourisme canin niveau 1 et 2</p>
                <p>- Intervenant PECCRAM</p>
                <p>- Initiateur Club FFST</p>
                <p>- Milieu assossiatif, présidente Esprit De Meute</p>
                <p>- Expérience dans le mushing (6 ans)</p>
            </div>

            <div className="refs">
                <NavLink to="/education-canine">
                    <div className="eduRef">
                        <img src={require("../assets/images/educcanineref.jpg")} alt="" draggable={false} />
                        <div className="square">
                            <h1 className="text">Education Canine</h1>
                        </div>
                    </div>
                </NavLink>
                
                <NavLink to="/chiens-de-traineaux">
                    <div className="traineauRef">
                        <img src={require("../assets/images/chientraineauxref.jpg")} alt="" draggable={false} />
                        <div className="square">
                            <h1 className="text">Chiens de Traineaux</h1>
                        </div>
                    </div>
                </NavLink>
            </div>

        <Footer />
        </div>
    );
};

export default Home;