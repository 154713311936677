import { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="footer-container">
                    <p className='footer-left footer-text'>Esprit De Meute © 2022</p>
                    <p className='footer-right footer-text'>Design by <a href="https://valdesign.me" className='footer-link'> ValDesign</a></p>
                    {/* <p>N° de SIRET : 538 695 735 00042</p>
                    <p>N° de RC Pro : 10914287004</p> */}
                </div>
            </footer>
        );
    }
}

export default Footer;