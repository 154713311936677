import { Component } from 'react';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import PresentDog from '../components/PresentDog';

class LaMeute extends Component {
    toggleContact() {
        const element = document.getElementById("contactPopup") as HTMLDivElement;

        element.classList.toggle("hidden");
    }

    render() {
        const data: { imageId: string, text: string, dogName: string }[] = [
            {
                imageId: "1DH6LlvBRspjDCh5KLS7Oob-8etgNDawI",
                text: "YEPA 4 ans, femelle Husky née le 20/11/2017\nappelée « Maman » car elle nous a fait 6 merveilleux chiots le 11/09/2020.\n\nSon vrai nom car elle est Lof, N’YEPA of Nashkaia, c’est une chienne merveilleuse, toujours joyeuse et joueuse, adore les chiots et les enfants, toujours de bonne humeur et partante pour le travail,\nchienne de tête à l’attelage, une véritable machine en cani trottinette deux chiens avec sa meilleure amie Laya,\nelle adore faire les cani marches, les visites pédagogiques et une très bonne médiatrice en éducation canine chez EDUC’TON POILU.",
                dogName: "Yepa"
            }, {
                imageId: "1eaMLJwo6VDs125izxzJT8b-WjxCI1kJk",
                text: "YVAR appelé \"Vavar\", Lof R’Yvar,\nmâle husky âgé de bientôt deux ans, né le 11/09/2020,\nfils de Yepa qui est à la maison et de Django Des Aragnouets.\n\nUn amour de chien, très joueur mais avec un caractère qui  s’affirme en grandissant.\n\nSon idole c’est son demi-frère Pandore,\nil le vénère comme un Dieu.\n\nYvar est un gros travailleur,\ntoujours partant et qui peut bosser à tous les postes,\nune grande écoute et sérieux à l’attelage.\n\nIl est aussi à l’aise en Canitrotinette un, deux chiens,\nparticipe régulièrement aux Canimarches et communique facilement avec ses congénères dès le moment où ils sont respectueux.",
                dogName: "Yvar"
            }, {
                imageId: "1wBPI_jzRPhS1vH3DuNaj62rbbDi4jj5z",
                text: "JAÏKA\nFemelle Husky âgée de 7 ans (née le 18/12/2014)\n\nElle pratique la Cani Balade et l’attelage, elle est leader (chien de tête) dans la team randonnée.\n\nJAÏKA appelée aussi « La Duchesse » adore la caniTrottinette, le CaniVTT et le Canicross également.\n\nC’est une chienne adorable, câline, très proche de l’humain et qui accompagne EDUC’TON POILU lors des prestations PECCRAM (Programme d'Éducation à la Connaissance du Chien et au Risque d'Accident par Morsure) en milieu scolaire.",
                dogName: "Jaïka"
            }, {
                imageId: "1W3m0mu8nopkLj0p22OEXXTCBlI_Dx3NC",
                text: "FALCO, notre doyen, 8 ans, né le 13/08/2014.\n\nFALCO appelé aussi « Chouchou » est arrivé à la maison et avait déjà deux ans, c’est un sauvetage issu de maltraitance et ayant eu plusieurs propriétaires avant nous (4),\nun chien très craintif mais avec de la patience et de l’amour tout c’est arrangé.\n\nFALCO a fait du canicross avec notre fille et a même été champion d’une compétition qui s’appelait la « Transpyr à six pattes » en catégorie enfant 2.\n\nMaintenant il fait de la Canimarche et adore les visites pédagogiques c’est aussi un super tonton avec les chiots de la maison.",
                dogName: "Falco"
            }, {
                imageId: "1avawEoiI9QTHC585IBuE_1-C-ne-AWlg",
                text: "MAÏKO dit « la racaille » 5 ans, mâle Husky né le 08/10/2016\n\nUn chien robuste, très solitaire au sein de la meute, qui aime se faire désirer,\nune force de la nature et très bavard. A l’attelage il est wheel,\nc’est-à-dire devant le kart ou le traîneau,\ntrès rarement en tête car Monsieur a tendance à faire ce qu’il veut.\n\nIl a fait énormément de canicross notamment la « Jura Chien »,\n4 jours de course et un podium en canicross sur la troisième marche en V2,\nmaintenant il adore la trottinette deux chiens en loisirs,\nl’attelage lors des randonnées,\nles visites pédagogiques car il adore les papouilles et les canimarches.",
                dogName: "Maïko"
            }, {
                imageId: "1SNkJaqr0tLRgd4dAe1w_vyUMb-7tW5sU",
                text: "LAYA, femelle Husky âgée de 5 ans, née le 01/04/2022 appelée aussi « Yayou ou canichou » mais officiellement N’LAYA car elle est Lof,\nissue d’une belle lignée de travail et qui vient de chez notre amie Lise Jourdes,\nune chienne exceptionnelle,\nchienne de tête à l’origine, qui était très prometteuse,\nmalheureusement elle souffre d’une cochonnerie de maladie,\nl’épilepsie.\n\nElle va quand même très bien avec son traitement,\net travaille maintenant plutôt au poste de Swing Dog,\nchiens de tête.\n\nElle nous a fait une superbe saison de baptêmes en traîneau l’hiver dernier.\n\nLAYA adore les cani marches,\nles visites pédagogiques,\nles balades en libertés avec nous et c’est une super nounou aussi avec les chiots.",
                dogName: "Laya"
            }, {
                imageId: "1dxNTn5WR0IqkQvas9KLb_ZvPYx6AHiss",
                text: "NANOUCK dit « le poilu » mâle Husky âgé de 5 ans,\nné le07/06/2017 un chien vaillant,\nsolide,\ndoux et discret.\n\nUne peluche pleine de tendresse mais avec son petit caractère,\ntrès sensible et du coup peureux,\nil ne supporte pas les bousculades.\n\nUn bon wheel Dog à l’attelage,\nune saison magnifique en baptêmes traîneau avec lui,\njamais fatigué toujours partant,\nbref un bon chien plein de tendresse.",
                dogName: "Nanouck"
            }, {
                imageId: "1cZjxlwR0bPqMUstbjDXX-NYvJEAkXWVu",
                text: "SNOW, mâle Husky âgé de 4 ans, né le 08/11/2017\n\nAppelé aussi « Monsieur Patate »,\nle roi du Selfie SNOW est également un sauvage,\nsans maltraitance,\nsimplement un couple qui n’avait pas réalisé l’investissement d’un tel chien.\n\nIl aime tout le monde,\nune vraie perle,\nil est assez fainéant quand il veut,\nil adore les papouilles et sera prochainement mon coéquipier pour de la médiation animale,\nc’est un excellent wheel Dog à l’attelage,\nun bon randonneur,\nil adore aussi la cani trottinette avec son copain MAÏKO, un parfait compagnon en Cani marche mais ce qu’il aime le plus ce sont les visites pédagogiques avec les enfants.",
                dogName: "Snow"
            }, {
                imageId: "1HCMdKOWXbH3ioQ3IxudcOib5c1XJUrZd",
                text: "NOXX mâle Husky âgé de 4 ans, né le 18/12/2017 appelé depuis le début « L’amoureux »,\nil est Lof et vient des Landes chez une amie Nathy Holzaphel,\nc’est un chien admirable,\nle chef de la Meute,\nla force tranquille,\ntoujours partant pour tout,\nrégulateur en éducation canine chez EDUC’TON POILU,\nson poste à l’attelage est Wheel Dog,\nune saison irréprochable en baptêmes traîneau,\ntoujours volontaire et heureux.\n\nSes plus grandes qualités sont la gentillesse et l’envie de toujours nous accompagner en Cani marche.",
                dogName: "Noxx"
            }, {
                imageId: "18B8gy9NFnQs9gscvVW_NkRLv5oZnN5zF",
                text: "ORKAN des Loups des Pyrénées,\nmâle Husky lof,\nâgé de 3 ans né le 11/12/2018 appelé aussi « tétouille » car il a un penchant pour les plaids en polaire.\n\nC’est un chien énergique,\ntrès gentil avec les humains mais à des difficultés pour gérer ses émotions avec ses congénères.\n\nParfait pour les Cani marches avec des personnes ayant une bonne forme physique il est une pièce maîtresse à l’attelage,\nson poste peut varier,\nwheel ou swing et apprécie énormément les visites pédagogiques.",
                dogName: "Orkan"
            }, {
                imageId: "1Rv5c_4E0r7Do-qIYau8x60sMaWg14p_m",
                text: "PADME Du Menez Des Glaziks,\nfemelle Husky âgée de 4 ans,\nnée le 08/07/2019,\nelle est aussi appelée « Princesse ».\n\nPADME est une Bretonne,\nelle vient de chez mes amis Marie et Émile Le Page.\n\nUne chienne très volontaire,\nvaillante,\ntrès rapide,\nelle travaille en wheel à l’attelage au côté de son ami Pandore que ce soit en kart terre ou traîneau,\nelle pourrait être leader mais son caractère très prononcé lui fait défaut.\n\nElle pratique à merveille la Cani trottinette,\nle caniVTT en loisirs et compétitions et participe aux séances de Cani marche.",
                dogName: "Padme"
            }, {
                imageId: "1XX7u5oLCjJQyVx4rohrs_cDFoqict92j",
                text: "PANDORE Des Aragnouets,\nmâle Husky âgé de 4 ans,\nné le 23/08/2019 il nous vient des Hautes Pyrénées de chez Frédéric Desbree.\n\nIl est aussi appelé « Panpan » ou « Le Prince ».\n\nUn chien formidable,\nun amour au quotidien,\nil s’entend avec tout le monde,\ntoujours bienveillant,\nbon,\nil ne supporte pas trop les chiots qui ont tendance à l’idolâtrer.\n\nAu travail c’est un guerrier,\nen wheel Dog mais aussi en Swing que ce soit sur terre ou neige,\nil travaille en Canitrotinette que ce soit seul ou en binôme avec son demi-frère Yvar mais aussi avec son amie Padme.\n\nIl participe régulièrement aux Canimarches car il adore rencontrer des nouveaux humains,\ndes congénères et durant les visites pédagogiques il séduit tout le monde.",
                dogName: "Pandore"
            }, {
                imageId: "1IvBcrCq0C_wJSP7F2KNNSns-0g8SyBB8",
                text: "NEYTIRI,\nfemelle Husky croisé Groenlandais âgé de deux ans née le 24/06/2020.\n\nCette beauté nous vient des Hautes Alpes chez Laureen et Tony de Passion Traîneau.\n\nAussi appelée « Choco » de par sa belle robe marron chocolat,\nune merveilleuse et douce chienne,\ncalme et très joueuse,\nune guerrière à l’attelage,\nse sent à l’aise à tous les postes même en leader avec une bonne coéquipière.\n\nElle raffole des Canimarches et accompagne en médiation Educ’ton Poilu pour communiquer avec des congénères un peu tendu.",
                dogName: "Neytiri"
            }, {
                imageId: "17moI8YmcX11Eek_S0o7_XoQndloBRdht",
                text: "ARIA,\nLof R’ARIA,\nfemelle husky âgée de bientôt deux ans,\nnée le 11/09/2020.\n\nFille de N’Yepa qui est à la maison et de Django des Aragnouets de chez Frédéric Desbree.\n\nCaractère identique à sa mère,\njoyeuse,\njoueuse,\ntoujours de bonne humeur,\nqui demande toujours à être câliner,\nune chienne remarquable et qui s’est découverte chienne de tête cet hiver en baptêmes traîneau,\nelle mène un attelage de 6/8 chiens d’une main de maître que ce soit sur terre ou neige accompagnée de sa sœur Arwen,\nsa mère Yepa et même son frère Yvar.\n\nUne chienne très proche et douée,\nelle pratique la canitrotinette avec sa sœur,\nle caniVTT et adore la Canimarche.",
                dogName: "Aria"
            }, {
                imageId: "1ZgKSg_p7OIIhDsBgW5HuyXhtxplFnlmH",
                text: "ARWEN,\nLof R’ARWEN, femelle husky âgée de bientôt deux ans,\nnée le 11/09/2020.\n\nFille de N’Yepa qui est à la maison et de Django des Aragnouets de chez Frédéric Desbree.\n\nCaractère plutôt timide au début puis joyeuse,\njoueuse quand elle a fait connaissance,\ntoujours de bonne humeur,\nqui demande souvent à être câliner,\nune chienne exceptionnelle douce et attentive qui s’est découverte chienne de tête cet hiver en baptêmes traîneau avec sa sœur,\nelle mène un attelage de 6/8 chiens que ce soit sur terre ou neige accompagnée de sa sœur Aria,\nsa mère Yepa et aussi son frère Yvar,\nle poste de Swing ne la dérange pas.\n\nUne chienne très proche et douée,\nelle pratique la canitrotinette avec sa sœur,\nle caniVTT et adore la Canimarche.\n\nUne fratrie exceptionnelle,\ntrès proche de la famille et aimante.",
                dogName: "Arwen"
            }, {
                imageId: "1ZDna4g9WMrAXsz0PbWHeNRskvpODaXlv",
                text: "CHEYENNE, appelée \"Chéché\" femelle Husky,\n9 mois,\nnée le 02/11/2021\n\nOriginaire de Mijanes,\nen Ariège,\nd’une mère au top,\nPrada et un père magnifique de lignée des pays de l’Est.\n\nUn grand coup de cœur,\nune chienne magnifique et tellement heureuse de communiquer sa joie,\nespiègle,\ntrès rapide et affectueuse.\n\nL’amie de tous, élevée depuis sa naissance chez une personne hors du commun ce pourquoi elle est si exceptionnelle.\n\nElle a fait toute les étapes en éducation chez Educ'ton POILU Education Canine,\na démarré la Canimarche et cet hiver commencera son apprentissage avec les filles et notamment sa meilleure amie Arwen.",
                dogName: "Cheyenne"
            }, {
                imageId: "1uGMmKpCHOFxEkuUt1lo8P5p9JKhQc7Ma",
                text: "MASKA,\nHusky croisé Groenlandais appelé « Patounet »\n\nJeune mâle âgé de 7 mois né le 22/01/2022\n\nIl nous vient de chez notre ami Sam,\nMusher au Plateau de Beille (Ariège) et de son élevage Nord Attitude / Des gardians dos beille,\nune force de la nature,\nun grand chanteur.\n\nÀ la fois très joueur mais aussi d’un grand calme,\nattentifs aux situations,\nil continue son apprentissage chez Educ'ton POILU Education Canine,\nil va bientôt commencer son apprentissage en Canimarche et l’on va attendre qu’il grandisse pour l’initier aux différentes pratiques de sports canins.\n\n Il sera aussi initié prochainement à la médiation animale en milieu médical.",
                dogName: "Maska"
            }, {
                imageId: "1RXPTd6FftTTv4hMRklTW4BvXwtgBdiuf",
                text: "TOKYO,\nappelée « Choupette »,\nAlaskan Husky âgée de 4 mois,\nnée le 30/04/2022,\nune petite bombe,\nhyper intelligente,\npeur de rien,\nà l’aise partout voire même un peu trop territoriale.\n\nElle nous vient de chez notre ami Sam,\nMusher au plateau de Beille (Ariège) et de son élevage Nord Attitude / Des gardians dos beille.\n\nTokyo à un regard qui ne laisse pas indifférent ni son caractère.\n\nElle a commencé son éducation chez Educ'ton POILU Education Canine et nous allons la laisser tranquillement grandir.",
                dogName: "Tokyo"
            }, {
                imageId: "194Nf2SDm9P-W7uFo6XSbEMhdNwV1EiDb",
                text: "NIXIE Adara Brine,\nfemelle Border Collie âgée de 5 ans\n\nElle est Lof de lignée travail,\nnée le 04/03/2017.\n\nCette beauté hyper active et très intelligente participe régulièrement en éducation canine chez Educ'ton POILU Education Canine durant les cours collectifs en École des chiots mais aussi avec les chiens adultes pour l’apprentissage  de la communication canine.\n\nNIXIE a également deux autres missions,\nelle mène l’attelage des nordiques,\nmotive les chiens et nous prévient de la présence d’animaux sauvages lors des entraînements mais aussi elle fait parfaitement la bergère avec les huskys lors des montées dans le fourgon.",
                dogName: "Nixie"
            }, {
                imageId: "17VIQtYEgOokb9nIH5r6RPRvKkFLhgp0L",
                text: "TORUK MAKTO née le 26/12/2017 appelé aussi « Toto »,\nmâle agé de 5 ans c’est un ESD (EuropeanSledDog) de l’élevage TCSP (Team Des Chiens de Sport) de chez Mathilde De Munter,\nun super chien,\nle meilleur ami de tous les huskys,\nune machine de guerre en Canitrotinette et un amoureux du canapé.\n\nC’est un merveilleux compagnon de vie,\ntoujours ok pour les câlins mais se transforme en guerrier dès qu’il a un harnais sur le dos.\n\nIl participe en Canitrotinette à des compétitions et en loisirs mais aussi il pratique le caniVTT.",
                dogName: "Toruk"
            }, {
                imageId: "16n1RUOZQqaL86o63KZtchGGm8ygdt3_I",
                text: "Skholl Du Domaine Des Crocs De Nessy,\nmâle Berger Hollandais âgé de 17 mois (né le 24/03/2021).\n\nIl nous vient d’un super élevage,\nspécialisé dans le BH et Malinois.\n\nSkholl est le super copain de notre Team de nordiques mais aussi leurs protecteurs,\nil a en fin de saison dernière été initié au caniVTT et va pour cette nouvelle saison 2022/23 commencer les compétitions et les sorties loisirs.\n\nC’est un chien très proche des humains mais n’apprécie pas les étrangers à deux ou quatre pattes qui pénètrent sans autorisation sur son territoire.",
                dogName: "Skholl"
            }
        ];

        return (
            <>
                <Navigation />

                <div className="contactPopup hidden" id="contactPopup">
                    <ContactForm />
                    <button className="cancelContact" onClick={this.toggleContact}>Annuler</button>
                </div>
                
                <div className="dogsList">
                    <div className="infosParrai">
                        <h1>NOTRE MEUTE</h1>
                        <h1>PARRAINEZ L’UN DE NOS CHIENS</h1>

                        <br />

                        <h3>Pourquoi parrainer un chien ?</h3>
                        <p>- Pour participer au frais d'alimentation, de soins (vétérinaires et autres soins quotidiens)</p>
                        <p>- Achat de matériel adapté (coliers, harnais, lignes...)</p>
                        <p>- Entretien des enclos, cages...</p>
                        <p>Toujours dans le but que nos chiens vivent et vous reçoivent en parfaite santé et dans des conditions idéales.</p>

                        <br />

                        <h3>Formule PARRAINAGE 60€ (Carte Nominative valable 1 an) :</h3>
                        <p>- Droit de Visite 1 fois par Mois pendant 1h (sur RDV)</p>
                        <p>- Une Cani-Balade d’1 heure pour 1 personne (sur RDV)</p>
                        <p>- En cadeau : 1 Mug avec photo du chien parrainé + Photo du Chien</p>

                        <br />

                        <h3>Formule PARRAINAGE 120€ (Carte Nominative valable 1 an):</h3>
                        <p>- Droit de Visite 1 fois par Mois pendant 1h (sur RDV)</p>
                        <p>- 1 Cani-Balade d’1 heure pour 1 personne (sur RDV)</p>
                        <p>- En cadeau : 1 Mug avec photo du chien parrainé + Photo du Chien</p>
                        <p>- … et 1 Balade en Kart à Chien de 30 minutes pour 1 Personne sur RDV</p>
                    </div>

                    {data.map((dog, index) => (<PresentDog data={dog} key={index} toggleContact={this.toggleContact} />))}
                </div>

                <Footer />
            </>
        );
    }
}

export default LaMeute;