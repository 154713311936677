import { Component } from 'react';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import Tarif from '../components/Tarif';

class ChienTraineaux extends Component {
    state: Readonly<{ rows: any[] }>;

    // DECOUVERTE DU KART A CHIENS	50 €	

    constructor(props: any) {
        super(props);

        this.state = {
            rows: []
        }
    }
    
    renderRows(): JSX.Element {
        const sheetId = "1Ho5yQYB6wdWrACQgw6mKVcz3ITLMbJFmrlE-nQvRBcY";
        const base = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?`;
        const sheetName = 'chientraineaux';
        const query = encodeURIComponent('Select *');
        const url = `${base}&sheet=${sheetName}&tq=${query}`;

        let rows: any[] = []
        
        fetch(url)
            .then(res => res.text())
            .then(rep => {
                const jsonData = JSON.parse(rep.substring(47).slice(0, -2));
                jsonData.table.rows.forEach((row: any) => {
                    const rowData = row.c.slice(0, 3);

                    rows.push(rowData);
                });
            }).then(() => this.setState({ rows: rows }))
        
        return (
            <div>
                {this.state.rows.map((row, index) => <Tarif data={row} key={index} />)}
            </div>
        );
    }

    render() {
        return (
            <div>
                <Navigation />
                
                <h1 className='tarifsTitle'>Nos Tarifs</h1>
                <div className="tarifs">
                    <img src={require("../assets/images/chienstraineaux.jpg")} className='imgBack dogs' alt='' draggable={false} />
                    {this.renderRows()}
                </div>

                <Footer />
            </div>
        );
    }
}

export default ChienTraineaux;