const HamburgerMenu = () => {
    const toggleNav = () => {
        const nav = document.getElementById('navigation');
        const contactPopup = document.getElementById("contactPopup") as HTMLDivElement;
        nav?.classList.toggle('is-open');
        if(!contactPopup?.classList.contains("hidden")) contactPopup?.classList.toggle("hidden")
    }

    return (
        <div id="menuToggle">
            <input id="menuInput" type="checkbox" onChange={toggleNav}/>

            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default HamburgerMenu;