import { Component, MouseEventHandler } from 'react';

class PresentDog extends Component {
    props!: Readonly<{
        data: {
            imageId: string,
            text: string,
            dogName: string
        },
        toggleContact?: MouseEventHandler<HTMLDivElement>
    }>;

    render() {
        return (
            <div className="dogBlock">
                <div className="infos">
                    <img src={"https://drive.google.com/uc?export=view&id="+this.props.data.imageId} alt="" className="dogImage" />
                    <div className="dogText">{this.props.data.text.split("\n").map(function(item, index) {
                        return (
                            <div key={index}>
                                {item}
                                <br />
                            </div>
                        )
                    })}</div>
                </div>
                <div className='goContact' onClick={this.props.toggleContact}>Parrainer un chien</div>
            </div>
        );
    }
}

export default PresentDog;