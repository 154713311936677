import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = (props: { text?: string }) => {
    const nameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const messageRef = useRef<HTMLTextAreaElement>(null);
    
    const createToast = (props: { type: string; }) => {
        const toast = document.createElement("div");
        toast.classList.add("toast");
        toast.id = `toast-${Date.now()}`;
        
        let hovering = false;
        let currentTimeout = 5500;
        let remainingTimeout = currentTimeout;
        let interval = 100;
        
        const p = document.createElement("p");
        p.textContent = props.type === "success" ? "Message envoyé avec succès!" : "Erreur lors de l'envoi du message";
        toast.appendChild(p);
        
        const button = document.createElement("button");
        button.classList.add("close-toast");
        button.textContent = "X";
        
        button.addEventListener("click", () => toast.remove());
        
        toast.appendChild(button);
        
        const toastBar = document.createElement("span");
        toastBar.classList.add("toast-bar");
        toast.appendChild(toastBar);
        
        toast.addEventListener("mouseenter", () => hovering = true);
        
        toast.addEventListener("mouseleave", () => hovering = false);
        
        let toastBarWidth = 100;
        const countdownInterval = setInterval(() => {
            if (hovering) currentTimeout = remainingTimeout;
            else {
                toastBarWidth -= 2;
                toastBar.style.width = `${toastBarWidth}%`;
                remainingTimeout -= interval;
                
                if (remainingTimeout <= 0) {
                    clearInterval(countdownInterval);    
                    toast.remove();
                }
            }
        }, interval);
        
        return toast;
    };
    
    const emailSubmit = (event: any) => {
        event.preventDefault();
        
        const params = {
            name: nameRef.current!.value,
            email: emailRef.current!.value,
            phone: phoneRef.current?.value || `(Le numéro peut être absent si ${nameRef.current!.value} ne l'a pas précisé)`,
            message: messageRef.current!.value
        }
        
        const toastDiv = document.querySelector(".toasts") as HTMLDivElement;
        
        emailjs.send("service_08pst8k", "template_b8xlzqg", params, "_AafCrJlkpvAZE82_")
        .then((result) => {
            console.log(result.text);
            toastDiv.appendChild(createToast({ type: "success" }));
        }).catch((error) => {
            console.log(error.text);
            toastDiv.appendChild(createToast({ type: "error" }));
        });
        
        nameRef.current!.value = "";
        emailRef.current!.value = "";
        phoneRef.current!.value = "";
        messageRef.current!.value = "";
    };
    
    return (
        <>
        <div className="toasts"></div>
        <form
        className="contactForm"
        onSubmit={emailSubmit}
        autoComplete="off"
        >
        <h1>Contact</h1>
        <h4>Pour en savoir plus, n'hésitez pas à me contacter, je serai ravie d'échanger avec vous.</h4>
        <div className='inputs'>
        <input className="contactInput" type="text" placeholder="NOM*" name="name" required ref={nameRef} />
        <input type="email" placeholder="EMAIL*" name="email" required ref={emailRef} />
        <input type="tel" placeholder="TELEPHONE" name="phone" pattern="[0-9]{10}" ref={phoneRef} />
        </div>
        <div>
        <textarea placeholder="VOTRE MESSAGE*" name="message" required ref={messageRef} value={props.text} />
        </div>
        <div>
        <button type="submit">ENVOYER</button>
        </div>
        </form>
        </>
        );
    };
    
    export default ContactForm;