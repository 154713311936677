import { NavLink } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';
import { FaArrowUp } from "react-icons/fa";

const Navigation = () => {
    const toggleNav = () => {
        const nav = document.getElementById('navigation');
        const menuInput = document.getElementById('menuInput') as HTMLInputElement;
        nav?.classList.toggle('is-open');
        menuInput.checked = !menuInput.checked;
    }

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div>
            <div className="left-header">
                <img src={require("../assets/images/logo.png")} alt="website-logo" draggable={false} />
            </div>
            <NavLink to="/" className="website-title">
                <p>Esprit de meute</p>
            </NavLink>
            <HamburgerMenu />
            <div className='navigation' id="navigation">
                <ul>
                    <NavLink to="/" className={nav => nav.isActive ? "isActive" : ""}>
                        <li onClick={toggleNav}>Accueil</li>
                    </NavLink>
                    <NavLink to="/meute" className={nav => nav.isActive ? "isActive" : ""}>
                        <li onClick={toggleNav}>La Meute</li>
                    </NavLink>
                    <NavLink to="/education-canine" className={nav => nav.isActive ? "isActive" : ""}>
                        <li onClick={toggleNav}>Education Canine</li>
                    </NavLink>
                    <NavLink to="/chiens-de-traineaux" className={nav => nav.isActive ? "isActive" : ""}>
                        <li onClick={toggleNav}>Chiens de Traineaux</li>
                    </NavLink>
                    <a href="https://www.facebook.com/espritdemeute" target="_blank" rel="noreferrer">
                        <li onClick={toggleNav}>Actualités</li>
                    </a>
                    <NavLink to="/contact" className={nav => nav.isActive ? "isActive" : ""}>
                        <li onClick={toggleNav}>Contact</li>
                    </NavLink>
                    <NavLink to="/mentionslegales" className={nav => nav.isActive ? "isActive" : ""}>
                        <li onClick={toggleNav}>Mentions Légales</li>
                    </NavLink>
                </ul>
            </div>

            <div className="backTop" onClick={scrollTop}>
                <FaArrowUp />
            </div>
        </div>
    );
};

export default Navigation;