// const EducationCanine = () => {
//     // https://docs.google.com/spreadsheets/d/1Ho5yQYB6wdWrACQgw6mKVcz3ITLMbJFmrlE-nQvRBcY/edit?usp=sharing

//     const sheetId = "1Ho5yQYB6wdWrACQgw6mKVcz3ITLMbJFmrlE-nQvRBcY";
//     const base = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?`;
//     const sheetName = 'educationcanine';
//     const query = encodeURIComponent('Select *');
//     const url = `${base}&sheet=${sheetName}&tq=${query}`;

//     let rows: any[] = [];

//     const init = () => {
//         fetch(url)
//         .then(res => res.text())
//         .then(rep => {
//             const jsonData = JSON.parse(rep.substring(47).slice(0, -2));
//             jsonData.table.rows.forEach((row: any) => {
//                 const rowData = row.c.slice(0, 3);

//                 console.log(rowData);

//                 rows.push(rowData);
//             });
//         });
//     }

//     useEffect(() => {
//         init();
//     })
    
//     return (
//         <div>
//             <Navigation />
//         </div>
//     );
// };

import { Component } from 'react';
import Footer from '../components/Footer';
import Navigation from "../components/Navigation";
import Tarif from "../components/Tarif";

class EducationCanine extends Component {
    state: Readonly<{ rows: any[] }>;


    constructor(props: any) {
        super(props);

        this.state = {
            rows: []
        }
    }
    
    renderRows(): JSX.Element {
        const sheetId = "1Ho5yQYB6wdWrACQgw6mKVcz3ITLMbJFmrlE-nQvRBcY";
        const base = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?`;
        const sheetName = 'educationcanine';
        const query = encodeURIComponent('Select *');
        const url = `${base}&sheet=${sheetName}&tq=${query}`;

        let rows: any[] = []
        
        fetch(url)
            .then(res => res.text())
            .then(rep => {
                const jsonData = JSON.parse(rep.substring(47).slice(0, -2));
                jsonData.table.rows.forEach((row: any) => {
                    const rowData = row.c.slice(0, 3);

                    rows.push(rowData);
                });
            }).then(() => this.setState({ rows: rows }))
        
        return (
            <div>
                {this.state.rows.map((row, index) => <Tarif data={row} key={index} />)}
            </div>
        );
    }

    render(): JSX.Element {
        return (
            <div>
                <Navigation />

                <div className="infosEduc">
                    <h1>Education Canine</h1>
                    <img className='imgEducTonPoilu' src={require("../assets/images/eductonpoilu.jpg")} alt="" />
                    <div className="presentation">
                        Vous rencontrez des difficultés avec votre chiot ou
                        chien adulte ou vous souhaitez tout simplement
                        l’éduquer en lui proposant des activités complémentaires ?
                    </div>
                    <div className="presentation">
                        Venez découvrir notre centre d’éducation canine,
                        EDUC’TON POILU, situé à Daumazan sur Arize en Ariège,
                        limitrophe Haute Garonne. Nous travaillons avec toutes
                        races de chiens mais nous sommes également spécialiste
                        du chien nordique.
                    </div>
                    <div className="presentation">
                        Vous adorez les huskys et vous voudriez
                        participer à nos Cani-Balades !

                        Venez à la rencontre des chiens nordiques de chez ESPRIT
                        DE MEUTE (activités toutes l’années hors neige)
                    </div>
                    <div className="presentation">
                        Vous aimez les chiens de Traîneau et vous
                        souhaiteriez les rencontrer dans nos visites pédagogiques ?
                    </div>
                    <div className="presentation">
                        Venez à la rencontre des chiens nordiques de chez ESPRIT
                        DE MEUTE
                    </div>
                    <div className="presentation">
                        Vous aimeriez découvrir nos sorties
                        en Kart à chiens (activités terre), traîneau
                        (neige) et profitez d’un baptême en traineau (sur neige) avec un professionel
                    </div>
                    <h4 className="presentation">
                        Vous êtes bien chez ESPRIT DE MEUTE !
                    </h4>
                    <div className="presentation">
                        Carole BRUNEAU, Éducateur Canin Diplômée d’état avec 8 ans d’expérience
                        dans les sports canins et musher chez Esprit de Meute.
                        Notre terrain de 3000m2, entièrement clôturé et sécurisé,
                        vous accueille pour de multiples activités canines.
                    </div>
                    <h4 className="presentation">
                        EDUCATION CANINE
                    </h4>
                    <div className="presentation">
                        - École Des P’tits Poilus :
                    </div>
                    <div className="presentation">
                        Retrouvez-nous un à deux samedi par mois (matin
                        ou après-midi).
                        Travail sur le terrain sécurisé pour de l’education de
                        base (rappel et marche en laisse, le stop, du assis et
                        couché, de l’initiation à la proprioception et du
                        médical training) mais aussi du travail sur le calme,
                        jeux de recherches et d’occupations.
                        Les séances se font également en milieu ouvert style
                        marchés locaux, en milieu urbain et aussi autour de
                        lac et sentiers forestiers.
                    </div>
                    <div className="presentation">
                        - Plusieurs Packs à votre disposition :

                        <p>- Packs chiots de 3 à 9 mois</p>
                        <p>- Packs ados de 10 à 18 mois</p>
                        <p>- Packs adultes au-delà de 18 mois</p>
                    </div>
                    <div className="presentation">
                        * Dans ces packs : un bilan, des cours individuels,
                        des cours collectifs en semaine ou les weekends
                    </div>
                    <div className="presentation">
                        (balades urbaines et campagnardes, des
                        canimarches et autres surprises...)
                        * Pour participer les chiens doivent être identifiés,
                        vaccinés y compris la toux de Chenil obligatoire (se
                        renseigner avec votre vétérinaire).
                    </div>
                    <div className="presentation">
                        - Bilans conseils à domicile (selon la distance) ou
                        sur le terrain de Daumazan sur Arize

                        <p>- L’Aventure continue chez les Poilus :</p>

                        - Forfait de cours collectifs sur le terrain ou en
                        balade éducative en milieu urbain ou en campagne
                        mais aussi en cani-marche.
                    </div>
                    <div className="presentation">
                        Ces séances se déroulent en semaine ou les
                        weekends (selon le planning et le nombre de
                        participants).
                        Bilan comportemental au préalable pour
                        participer.
                    </div>
                    <div className="presentation">
                        - La Récré des Poilus :

                        <p>- Forfait spécialement dédié pour les chiens ne
                        pouvant être en liberté (spécial chien nordique)</p>
                        <p>- Sur Rendez-vous et pas plus de 4 chiens (sociables
                        et vaccinés)</p>
                        <p>- Activités de détente sur un terrain de 3000m2</p>
                        <p>- Jeux avec mise à disposition de piscines et autres
                        ateliers de bien-être.</p>

                        <p>- Forfait cours individuels ou en collectifs et Cour
                        à la séance possible</p>
                        <p>PROGRAMME PECCRAM : Programme
                        d’Éducation à la Connaissance du Chien et au Risque
                        d’Accidents par Morsure.
                        Tarif sur Devis (Centre de Loisirs, Établissements
                        Scolaires, Comité d’entreprise, Animalerie ...)</p>
                        <p>- S’adresse aux enfants de 3 à 12 ans mais peut-être
                        effectué pour des adultes .</p>
                    </div>
                </div>

                <h1 className='tarifsTitle'>Nos Tarifs</h1>
                <div className="tarifs">
                    <img src={require("../assets/images/educationcanine.jpg")} className='imgBack' alt='' draggable={false} />
                    {this.renderRows()}
                </div>

                <Footer />
            </div>
        );
    }
}

export default EducationCanine;