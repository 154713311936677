import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ChienTraineaux from './pages/ChienTraineaux';
import Contact from './pages/Contact';
import EducationCanine from './pages/EducationCanine';
import Home from './pages/Home';
import LaMeute from './pages/LaMeute';
import MentionsLegales from './pages/MentionsLegales';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<Navigate to="/" />} />
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/education-canine' element={<EducationCanine />} />
        <Route path='/chiens-de-traineaux' element={<ChienTraineaux />} />
        <Route path='/meute' element={<LaMeute />} />
        <Route path='/mentionslegales' element={<MentionsLegales />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
