import { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Tarif extends Component {
    props!: Readonly<{ data: any; }>;

    render() {
        return (
            <div className='tarif'>
                <h1 className='tarifTitle'>{this.props.data[0].v}</h1>
                <h2 className='tarifPrice'>{this.props.data[1]!== null ? (this.props.data[1]?.f) : "Devis"}</h2>
                {this.props.data[2] !== null ? (<h4 className='tarifDesc'>{this.props.data[2].v}</h4>) : null}
                <NavLink to="/contact"><div className='goContact'>Contactez moi</div></NavLink>
            </div>
        );
    }
}

export default Tarif;